/* vt323-400normal - latin */
@font-face {
  font-family: 'VT323';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('VT323 Regular '),
    local('VT323-Regular'),
    url('./files/vt323-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/vt323-latin-400.woff') format('woff'); /* Modern Browsers */
}

